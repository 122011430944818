import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  // private dataSubject = new BehaviorSubject<any[]>([]);
  // data$ = this.dataSubject.asObservable();
  // private currentId = 0; 
  // data={
  //   id: 1,
  //   GreetingPhraseforEnglish: "Hello, How can i help you?",
  //   GreetingPhraseforJapanese: "こんにちは、どうしましたか？",
  //   GreetingPhraseforMarathi: "नमस्कार",
  //   aIPrompt: "You are phone reception is at geneses",
  //   assistantsName: "Pranit",
  //   businessName: "Waanee.AI",
  //   greetingPhrase: "Hii There",
  //   selectLanguages: ['English', 'Marathi', 'Japanese'],
  //   voiceSelection: "3"
  // }

  constructor(private http: HttpClient){ 
    // this.addData(this.data)
  }  

  // addData(newData: any) {
  //   const currentData = this.dataSubject.getValue();
  //   this.currentId++; // Increment the ID
  //   newData.id = this.currentId; // Assign the new ID to the data
  //   this.dataSubject.next([...currentData, newData]);
  // }

  getListData(url:any){
    return this.http.get(url)
  }

  getDetailbyId(url:any, data:any){
    return this.http.post(url, data)
  }

  postData(url:any, data:any){
    return this.http.post(url, data)
  }

  putData(url:any, data:any){
    return this.http.put(url, data)
  }

  deleteData(url:any, data:any){
    return this.http.post(url,data)
  }


}
